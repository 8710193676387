import React from 'react'

const TagList = props => {
    const { tags } = props
    return (
        <>
            {tags &&
                tags.length > 0 &&
                tags.map((tag, key) => (
                    <span
                        key={key}
                        className="flex-shrink-0 inline-block ml-1 px-2 py-0.5 text-gray-500 text-xs leading-4 font-medium bg-ffyellow rounded-full"
                    >
                        {tag}
                    </span>
                ))}
        </>
    )
}

export default TagList
