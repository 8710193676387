import React, { useState } from 'react'

// TODO - Add cool color schemes for facts!
// const topColorMap = {
//     1: 'bg-red-500',
//     2: 'bg-gray-500',
//     3: 'bg-purple-500',
// }

// const bottomColorMap = {
//     1: 'bg-red-200',
//     2: 'bg-gray-200',
//     3: 'bg-purple-200',
// }

const FunRating = (props) => {
    const [score, setScore] = useState(props.score)

    if (score > 100) {
        setScore(100)
    }

    return (
        <div className="relative pt-1 mb-4" id="funRating">
            {props.loading ? (
                <div className="rounded h-2 w-full animate-pulse bg-ffpurple-200"></div>
            ) : (
                <>
                    <div
                        className={`overflow-hidden h-2 text-xs flex rounded bg-ffpurple-200`}
                    >
                        <div
                            style={{ width: `${props.score}%` }}
                            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-ffpurple-500`}
                        ></div>
                    </div>
                    {props.voteCount && (
                        <div className="text-center text-sm italic text-ffpurple-500">
                            {props.score}% rating with {props.voteCount}{' '}
                            {props.voteCount > 1 ? 'votes' : 'vote'}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default FunRating
