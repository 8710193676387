import API from '@aws-amplify/api'
import { getUserName } from '../utils/auth'
import config from '../aws-exports'

const apiName = 'ffchecker'

API.configure(config)

export const getFacts = () => {
    return API.get(apiName, '/facts/')
        .then(response => {
            if (response.Items && response.Items.length > 0) {
                return response.Items
            }
            return []
        })
        .catch(error => {
            console.log(error.response)
            return []
        })
}

export const getMyFacts = (author) => {
    return API.get(apiName, `/facts/myFacts/${author}`)
        .then(response => {
            if (response.Items && response.Items.length > 0) {
                return response.Items
            }
            return []
        })
        .catch(error => {
            console.log(error.response)
            return []
        })
}

export const getFact = factId => {
    console.log('GET FACT')
    return API.get(apiName, `/facts/${factId}`)
        .then(data => {
            if (data && data.Item) {
                return data.Item
            }
            return {}
        })
        .catch(error => {
            console.log('FAIL FAIL FAIL')
            console.log(error.response)
            return {}
        })
}

export const delFact = factId => {
    return API.del(apiName, `/facts/${factId}`)
        .then(() => ({}))
        .catch(error => {
            console.log(error.response)
            return {}
        })
}

export const putFact = fact => {
    const init = {
        body: fact,
    }

    return API.put(apiName, `/facts/${fact.id}`, init)
        .then(data => {
            if (data && data.successful === true) {
                return data
            }
            return { successful: false }
        })
        .catch(error => {
            console.log(error.response)
            return { successful: false }
        })
}

export const createFact = fact => {
    let newFact = fact
    newFact.factCheckStatus = false
    newFact.author = getUserName()
    newFact.authorImg = ''
    newFact.funRating = 100
    newFact.datePosted = new Date().toLocaleDateString()
    newFact.numTotalRatings = 1
    newFact.numPositiveRatings = 1

    const init = {
        body: newFact,
    }

    return API.post(apiName, '/facts', init)
        .then(data => {
            if (data && data.successful === true) {
                return data
            }
            return { successful: false }
        })
        .catch(error => {
            console.log(error.response)
            return { successful: false }
        })
}

export const voteOnFact = (factId, votePreference) => {
    const init = {
        body: { votePreference: votePreference },
    }

    return API.post(apiName, `/facts/${factId}/vote`, init)
        .then(data => {
            if (data && data.successful === true) {
                return data
            }
            return { successful: false }
        })
        .catch(error => {
            console.log(error.response)
            return { successful: false }
        })
}
