import React from 'react'
import NotFunSvg from '../../icons/NotFunSvg'
import FunSvg from '../../icons/FunSvg'

const FunFactSkeletonList = ({ showBtns = true, cardCount = 4 }) => {
    return (
        <>
            {[...Array(cardCount)].map((item, index) => (
                <li key={index} className="col-span-1 bg-gray-50 rounded-lg shadow">
                    <div className="w-full flex items-center justify-between pl-6 pr-6 pt-6 pb-2 space-x-6">
                        <div className="flex-1">
                            <div className="flex items-center space-x-3">
                                <div className="rounded h-5 w-64 mb-2 animate-pulse bg-gray-300" />
                            </div>
                            <div className="rounded h-4 w-full mb-1 animate-pulse bg-gray-300"></div>
                            <div className="rounded h-4 w-full mb-1 animate-pulse bg-gray-300"></div>
                            <div className="rounded h-4 w-24 animate-pulse bg-gray-300"></div>
                        </div>
                    </div>
                    <div className="pl-6 pr-6">
                        <div
                            className='rounded h-2 mb-4 mt-4 w-full animate-pulse bg-purple-300'
                        ></div>
                    </div>
                    {showBtns &&
                        <div className="-mt-px flex">
                            <div className="w-0 flex-1 flex border-r border-gray-200 ">
                                <div
                                    className="animate-pulse cursor-not-allowed relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                                >
                                    <NotFunSvg />
                                    <span className="ml-3">Not Fun</span>
                                </div>
                            </div>
                            <div className="-ml-px w-0 flex-1 flex">
                                <div
                                    className="animate-pulse cursor-not-allowed relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                                >
                                    <FunSvg />
                                    <span className="ml-3">Fun</span>
                                </div>
                            </div>
                        </div>
                    }
                </li>
            ))}
        </>
    )
}

export default FunFactSkeletonList
