import React, { useState, useEffect } from 'react'
import TagList from '../shared/TagList'
import NotFunSvg from '../icons/NotFunSvg'
import FunSvg from '../icons/FunSvg'
import { Link } from 'gatsby'
import VerifiedSvg from '../icons/VerifiedSvg'
import FunRating from '../shared/FunRating'

const FunFact = props => {
    const [fact, setFact] = useState(props.fact)
    const [hasVoted,] = useState(props.hasVoted)
    const [voting, setVoting] = useState(false)

    useEffect(() => {
        setFact(props.fact)
    }, [props.fact])

    const voteAsFun = factId => {
        setVoting(true)

        props.voteAsFunFn(factId).then(() => {
            setVoting(false)
        })
    }

    const voteAsNotFun = factId => {
        setVoting(true)
        props.voteAsNotFunFn(factId).then(() => {
            setVoting(false)
        })
    }

    return (
        <li className="col-span-1 bg-gray-50 rounded-lg shadow">
            <div className="w-full flex items-center justify-between pl-6 pr-6 pt-6 pb-2 space-x-6">
                <div className="flex-1">
                    <div className="flex justify-between space-x-3">
                        <div>
                            <h3 className="text-gray-900 whitespace-normal items-center text-lg leading-5 font-medium">
                                {fact.title}
                            </h3>
                        </div>
                        <div className="">
                            <Link
                                className="inline-flex items-center px-4 py-2 border border-ffpurple-500 shadow-sm text-sm font-medium rounded-md text-ffpurple-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ffpurple-500"
                                to={`/app/facts/${fact.id}`}
                            >
                                Details
                            </Link>
                        </div>
                    </div>
                    {fact.verified && <VerifiedSvg />}
                    <TagList tags={fact.tags} />

                    <p className="mt-1 text-gray-600 text-sm leading-5">
                        {fact.description}
                    </p>
                </div>

            </div>
            <div className="pl-6 pr-6">
                <FunRating
                    score={fact.funRating}
                    voteCount={fact.numTotalRatings}
                />
            </div>
            {!hasVoted && (
                <div className="-mt-px flex">
                    {voting && (
                        <>
                            <div className="w-0 flex-1 flex border-r border-gray-200 opacity-50 cursor-not-allowed">
                                <span className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                                    <NotFunSvg />
                                    <span className="ml-3">Not Fun</span>
                                </span>
                            </div>
                            <div className="-ml-px w-0 flex-1 flex opacity-50 cursor-not-allowed">
                                <span className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                                    <FunSvg />
                                    <span className="ml-3">Fun</span>
                                </span>
                            </div>
                        </>
                    )}

                    {!voting && (
                        <>
                            <div className="w-0 flex-1 flex border-r border-gray-200">
                                <button
                                    onClick={() => voteAsNotFun(fact.id)}
                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                                >
                                    <NotFunSvg />
                                    <span className="ml-3">Not Fun</span>
                                </button>
                            </div>
                            <div className="-ml-px w-0 flex-1 flex">
                                <button
                                    onClick={() => voteAsFun(fact.id)}
                                    className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                                >
                                    <FunSvg />
                                    <span className="ml-3">Fun</span>
                                </button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </li>
    )
}

export default FunFact;
